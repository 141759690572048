<template>
    <div>


        <v-navigation-drawer
            v-model="getThemeMode.verticalCompactSidebarDrawer"
            :color="
                $vuetify.theme.dark
                    ? 'dark'
                    : getThemeMode.verticalSidebarDrawerColor
            "
            :dark="
                getThemeMode.verticalSidebarDrawerColor != 'white'
                    ? true
                    : false
            "
            width="300px"
            app
            mobile-breakpoint="1120"
            class="shadow-sm rounded-r-md"
            floating
            height="100vh"
            :right="$vuetify.rtl"
        >
            <v-row class="fill-height flex-row flex" no-gutters>


                <vue-perfect-scrollbar
                    :settings="{suppressScrollX: true, wheelPropagation: false}"
                    class="h-100 rtl-ps-none ps scroll grow"
                    style="height: 100%"
                >
                    <v-list class="grow" max-width="230">
                        <template v-for="(item, i) in computedItems">
                            <base-item-group
                                v-if="item.children"
                                :key="`group-${i}`"
                                :item="item"
                            ></base-item-group>

                            <base-item v-else :key="`item-${i}`" :item="item" />
                        </template>
                    </v-list>
                </vue-perfect-scrollbar>
            </v-row>

        </v-navigation-drawer>

        <!-- end of sidebar drawer -->

        <!-- userDrawer -->

    </div>
</template>

<script>
import {items} from '../../data/navigation'
import {mapGetters, mapActions} from 'vuex'
export default {
    components: {
        UserDrawer: () => import('../common-drawer/UserDrawer.vue'),
        NotificationDrawer: () =>
            import('../common-drawer/NotificationDrawer.vue'),
        SearchDrawer: () => import('../common-drawer/SearchDrawer.vue')
    },
    data() {
        return {
            drawer: true,
            items: items,
            userDrawer: false,
            notificationDrawer: false,
            searchDrawer: false,
            items2: ['mdi-home', 'mdi-play'],
            imtemsAside: ['mdi-play']
        }
    },
    computed: {
        ...mapGetters(['getThemeMode']),
        computedItems() {
            return this.items.map(this.mapItem)
        }
    },
    methods: {
        ...mapActions(['changeCompactVerticalSidebarDrawer']),
        toggleSidebar() {
            this.changeCompactVerticalSidebarDrawer()
        },
        mapItem(item) {
            return {
                ...item,
                children: item.children
                    ? item.children.map(this.mapItem)
                    : undefined,
                title: item.title
            }
        }
    }
}
</script>
